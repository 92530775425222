
























import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput.vue';

export default defineComponent({
  name: 'uci51LCPrelab4Q1',
  components: {DragToRankInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        dragToRankAnswers: [],
      },
      dragToRankOptions: [
        'Add sodium hypochlorite solution dropwise to the reaction mixture.',
        'Monitor the reaction progress by TLC.',
        'Add acetic acid to the reaction mixture.',
        'Warm the reaction mixture using a water bath.',
        'Add extra bleach solution if necessary.',
        'Combine 4-$t$-butylcyclohexanol and acetone in the flask.',
      ],
    };
  },
});
